.rooms {
  overflow-y: auto;
  overflow-x: hidden;
}

.rooms::-webkit-scrollbar {
  width: 10px;
}

.rooms::-webkit-scrollbar-track {
  background: #212128; 
}

.rooms::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.rooms::-webkit-scrollbar-thumb:hover {
  background: #555; 
}