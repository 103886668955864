.header-button {
  display: inline;
  cursor: pointer;
  padding: 1rem;
  color: white;
  background-color: #3c6cde;
  border-radius: 1rem;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.header-button.btn-small {
  padding: 0.6rem;
  line-height: 1rem;
  font-size: 0.6rem;
}

.header-button.btn-white {
  color: #3c6cde;
  background-color: white;
  border: 0.1rem solid #3c6cde;
}

.header-button.btn-white:hover {
  color: white;
  background-color: #3c6cde;
}

.header-button.btn-red {
  color: red;
  background-color: white;
  border: 0.1rem solid red;
}

.header-button.btn-red:hover {
  color: white;
  background-color: red;
}

.header-button-btn > i {
  margin-right: 0.2rem;
}

.header-button-btn {
  white-space: nowrap;
}

.header-button.disabled {
  cursor: not-allowed !important;
  color: black !important;
  background-color: lightgray !important;
  border: 0.1rem solid black;
}
