.actions {
  display: flex;
  color: #818189;
  background-color: #2a354d7f;
  padding: 1rem;
}

.btn-new-msg {
  width: 80%;
  height: 2rem;
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 2rem;
}

.btn-new-msg > span > i {
  padding-left: 0.5rem;
  line-height: 2rem;
}