.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 350px;
  min-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #fff; /* A background-color is required */
  min-height: 100%;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

@media screen and (min-width: 780px) {
  .slideout-panel {
    margin-left: 350px;
    width: 100%;
  }

  .slideout-menu {
    display: block;
  }

  .btn-hamburger {
    display: none;
  }
}

#panel {
  width: 100%;
}
