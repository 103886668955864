.combo-item {
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;
}

.combo-item-text {
  display: none;
}
@media (min-device-width: 30em) {
  .combo-item-text {
    display: inline;
  }
}

.combo-item:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.combo-item:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.combo-item + .combo-item {
  border-left: 0.1rem solid #888;
}

.combo-item-icon {
  padding: 0 0.5rem 0 0.5rem;
}

.combo-item.blue {
  color: white;
  background-color: #3c6cde;
  border: 0.1rem solid #3c6cde;
}

.combo-item.blue:hover {
  color: #3c6cde;
  background-color: white;
}

.combo-item.white {
  color: #3c6cde;
  background-color: white;
  border: 0.1rem solid #3c6cde;
}

.combo-item.white:hover {
  color: white;
  background-color: #3c6cde;
}

.combo-item.red {
  color: white;
  background-color: red;
  border: 0.1rem solid red;
}

.combo-item.red:hover {
  color: red;
  background-color: white;
}

.combo-item.gray {
  color: white;
  background-color: #555;
  border: 0.1rem solid #555;
}

.combo-item.gray:hover {
  color: #555;
  background-color: white;
}
