#messages {
  padding: 0;
  color: #818189;
  background-color: #eaf0fd;
}

#messages .conversation {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#messages .conversation header {
  flex: 0 1 auto;
}

#messages .conversation .messages-box {
  display: flex;
  flex-grow: 1;
  flex-shrink: 10000;
  overflow-y: scroll;
  padding-top: 0.8rem;
  font-family: "Raleway", sans-serif;
}

#messages .conversation .messages-box::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

#messages .conversation .messages-box::-webkit-scrollbar-track {
  background: transparent;
}

#messages .conversation .messages-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

#messages .conversation .messages-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#messages .conversation .messages-box .msgs-box {
  height: 100%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-device-width: 30em) {
  #messages .conversation .messages-box .msgs-box {
    width: 95%;
  }
}

#messages .conversation .footer {
  display: flex;
  justify-content: center;
  background-color: #eaf0fd;
  padding: 1rem;
  min-height: 5rem;
}
@media (max-device-width: 30em) {
  #messages .conversation .footer {
    padding: 0;
  }
}

#messages > .conversation > .footer > .message-window {
  width: 80%;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem 1.5rem 0rem 3rem;
  font-size: 2rem;
  vertical-align: bottom;
  max-height: 20rem;
  display: flex;
  flex-direction: column;
}
@media (max-device-width: 30em) {
  #messages > .conversation > .footer > .message-window {
    width: 100%;
    border-radius: 0;
  }
}

#messages > .conversation > .footer > .message-window > .send {
  display: flex;
  flex-direction: row-reverse;
}

#messages > .conversation > .footer > .message-window > .send > i {
  color: #3c6cde;
  cursor: pointer;
  position: absolute;
  transform: translate(-1rem, -4rem);
}

#messages > .conversation > .footer > .message-window > textarea {
  width: calc(100% - 4rem);
  height: 3rem;
  min-height: 3rem;
  max-height: 9rem;
  color: #3c6cde;
  font-size: 1.5rem;
  border: none;
  border-bottom: 0.2rem solid #e6ecfc;
  padding-bottom: 0;
  padding-right: 4rem;
  font-family: "Raleway", sans-serif;
  line-height: 3rem;
  resize: none;
  outline: none;
}

#messages > .conversation > .footer > .message-window > textarea::placeholder {
  color: #3c6cde;
  font-family: "Raleway", sans-serif;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar {
  width: 10px;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar-track {
  background: transparent;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#messages .conversation .header .type {
  padding-right: 1rem;
}

.signature {
  font-size: 0.75rem;
  margin: 5px 0;
  min-height: 1rem;
}
