#contacts-list .tbl-name {
  font-weight: bold;
}

#contacts-list .tbl-number > span:not(:first-child) {
  padding-left: 1rem;
}

#contacts-list .tbl-number > span > i {
  width: 1rem;
}

#contacts-list .tbl-body > .tbl-row:hover {
  color: white !important;
  background-color: #2a354d;
  cursor: pointer;
  user-select: none;
}

#contacts-list .tbl-actions {
  text-align: right;
}

#contacts-list .tbl-actions a:link {
  color: inherit;
  text-decoration: none;
}

#contacts-list .tbl-actions a:visited {
  color: inherit;
  text-decoration: none;
}
