#rooms-column {
  color: #FFFFFF;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#rooms-column > .brand {
  display: flex;
  height: 8rem;
}

#rooms-column > .rooms {
  height: calc(100% - 14rem);
}

#rooms-column > .actions {
  height: 4rem;
}
