#messages-app {
  display: flex;
  flex-flow: row;
  height: 100%;
}

#messages-app #rooms-column {
  width: 18rem;
}

#messages-app #panel #messages {
  width: 100%;
  height: 100%;
}

#slideout {
  display: flex;
  flex-direction: row;
}
