.media-box {
  height: auto;
  font-size: 0;
  line-height: 0;
}

.media-box > img {
  min-height: 4rem;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.media-box-inbound {
  text-align: left;
}

.media-box-outbound {
  text-align: right;
}
