.tbl {
  display: table;
  width: 100%;
}
.tbl-header {
  display: table-row-group;
}
.tbl-header > .tbl-row {
  display: table-row;
}
.tbl-header > .tbl-row > .tbl-cell {
  display: table-cell;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1rem;
  color: #3d6bdd;
  border-bottom: 0.1rem solid black;
}
.tbl-body {
  display: table-row-group;
}
.tbl-body > .tbl-row > .tbl-cell {
  padding: 0.5rem;
  border-bottom: 0.1rem solid #dfe5fc;
}
.tbl-body > .tbl-row {
  display: table-row;
  padding-top: 0.5rem;
}
.tbl-body > .tbl-row:hover {
  color: #ffffff;
  background-color: #2a354d;
  cursor: pointer;
}
.tbl-body {
  display: table-row-group;
}
.tbl-cell {
  display: table-cell;
}
