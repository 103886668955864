html,
body {
  overflow: hidden;
  margin: 0;
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

#root {
  height: 100%;
}

.float-right {
  float: right;
}

.btn {
  display: flex;
  border-radius: 0.6em;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  padding: 0.6em;
}

.btn-circle {
  border-radius: 2em;
}

.btn-gray {
  color: #000 !important;
  background-color: #ccc;
}

.btn-green {
  color: #fff !important;
  background-color: #328041;
}

.btn-blue {
  color: #fff !important;
  background-color: #3c6cde;
}

.btn-dark-blue {
  color: #fff !important;
  background-color: #183c50;
}

.btn-light-blue {
  color: #fff !important;
  background-color: #80b8d8;
}

.row-nomargin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row-right {
  flex-flow: row-reverse;
}

.row-fill {
  flex-grow: 1;
}
