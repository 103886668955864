#messages .conversation .header {
  display: flex;
  align-items: center;
  color: #2a354d;
  background-color: #ffffff;
  font-size: 1.5rem;
  position: relative;
  padding: 1rem;
}
@media (min-device-width: 30em) {
  #messages .conversation .header {
    min-height: 6rem;
  }
}

#messages .conversation .header .menu {
  display: none;
  padding: 1rem;
}
@media (max-device-width: 30em) {
  #messages .conversation .header .menu {
    display: inline-block;
  }
}

#messages .conversation .header .pic {
  position: absolute;
  top: 0.5em;
  left: 0.75em;
}

#messages .conversation .header .pic img {
  max-height: 2em;
  border-radius: 50%;
  border: 1px solid #212128;
}

#messages .conversation .header .type-small {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: 10;
  font-size: 0.8em;
}

.header > .type {
  padding-left: 0.3em;
  padding-right: 2.3em;
}

.header-new > .resource > .type {
  display: inline-block;
  width: 4rem;
  padding-left: 0rem;
  padding-right: 0rem !important;
  text-align: center;
  font-size: 3rem !important;
}

.header-new > .resource > input {
  color: #444444;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-bottom: 2px solid #ccc;
  transition: all 0.28s ease;
  box-shadow: none;
  width: calc(100% - 4rem);
  font-size: 3rem !important;
}

.header-new > .btn-confirm-new {
  display: inline-block;
}

@media (min-device-width: 30em) {
  .header-new > .new-message-begin-btn {
    margin-bottom: 2rem;
  }
}

.header-new > .hdr-new-room > select {
  color: #444444;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.175rem 0.175rem 0.0875rem;
  border: 0;
  border-bottom: 2px solid #ccc;
  transition: all 0.28s ease;
  box-shadow: none;
  font-size: 3rem;
}

.header-new > .btn-confirm-new {
  color: #444444;
  margin-left: 2rem;
}

#messages .header-btns {
  width: 100%;
  display: flex;
}
@media (max-device-width: 30em) {
  #messages .header-btns {
    display: none;
  }
}

#messages .header-btns > .header-fill {
  flex: 1;
}

#messages .contact-name {
  white-space: nowrap;
}

#messages .contact-name .archived {
  color: #e57373;
}

.header-button:not(:first-child) {
  margin-left: 1rem;
}

/* Modal */
.popup-content {
  padding: 0rem !important;
  border-radius: 1rem;
  border: 0 !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-radius: 1rem 1rem 0 0;
  color: #1a3dc8;
  font-weight: bold;
  background-color: #e8edfb;
  border-bottom: 0.1rem solid #c5d3f5;
  padding: 1rem;
}

.modal-header > i {
  cursor: pointer;
}

.modal-content {
  padding: 1rem;
}

.modal-content > p {
  font-size: 1rem;
}

.modal-confirm {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
