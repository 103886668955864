#settings-app {
  display: flex;
  flex-direction: row;
  height: 100%;
}

#settings-app #panel {
  display: flex;
  flex-direction: column;
}

#settings-app a {
  text-decoration: none;
  color: #ffffff;
}

#settings-app a:visited {
  text-decoration: none;
  color: #ffffff;
}

#settings-app #settings-column {
  width: 18rem;
  color: #ffffff;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#settings-column .settings-selections {
  margin-left: 1.5rem;
}

#settings-column .settings-selections-header {
  display: block;
  background: #6a97fd;
  height: 2rem;
  line-height: 2rem;
  padding-left: 0.6rem;
  font-weight: bold;
  margin: 0.1rem 0.1rem 0.1rem 0;
}

#settings-column .settings-selection.active {
  color: black;
  background: #eaf0fd;
}

#settings-column .settings-selection {
  cursor: pointer;
  user-select: none;
  border-bottom: 0.1rem solid #3c6cde;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
  padding-left: 1.5rem;
}

#settings-column .settings-selection > i {
  padding-right: 0.5rem;
}

#settings-column .settings-selection:last-child {
  border-bottom: none;
}

#settings-app .content {
  width: 100%;
  background-color: #eaf0fd;
}

#settings-app .settings-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  font-size: 1.5rem;
  font-weight: bold;
  align-items: center;
  padding: 1rem;
}
@media (min-device-width: 30em) {
  #settings-app .settings-header {
    height: 6rem;
    max-height: 6rem;
  }
}
#settings-app .settings-header-msg {
  text-transform: capitalize;
}

#settings-app .settings-header-msg > i {
  padding-right: 1rem;
}

#settings-app .settings-header .menu {
  display: none;
  padding: 1rem;
}
@media (max-device-width: 30em) {
  #settings-app .settings-header .menu {
    display: block;
  }
}

#settings-app .settings-form {
  background-color: #eaf0fd;
  flex-grow: 1;
}
@media (min-device-width: 30em) {
  #settings-app .settings-form {
    padding: 2rem 4rem;
  }
}

#settings-app .settings-form-actons {
  display: flex;
  flex-direction: row-reverse;
}
#settings-app .settings-form-save {
  width: 10rem;
}

#settings-app .brand {
  height: 8rem;
}
