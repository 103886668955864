html,
body {
  overflow: hidden;
  margin: 0;
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

input:focus {
  outline: none;
}

select:focus {
  outline: none;
}

#root {
  height: 100%;
}

.float-right {
  float: right;
}

.btn {
  display: flex;
  border-radius: 0.6em;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  padding: 0.6em;
}

.btn-circle {
  border-radius: 2em;
}

.btn-gray {
  color: #000 !important;
  background-color: #ccc;
}

.btn-green {
  color: #fff !important;
  background-color: #328041;
}

.btn-blue {
  color: #fff !important;
  background-color: #3c6cde;
}

.btn-dark-blue {
  color: #fff !important;
  background-color: #183c50;
}

.btn-light-blue {
  color: #fff !important;
  background-color: #80b8d8;
}

.row-nomargin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row-right {
  flex-flow: row-reverse;
}

.row-fill {
  flex-grow: 1;
}

body {
  background-color: #333;
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

input:focus {
  outline: none;
}

.login {
  width: 20em;
  height: 20em;
  text-align: center;

  /* Centers Horizontal & Vertical */
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.login-header {
  color: #cccccc;
  background-color: #161628;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
}

.login-form {
  background-color: #fff;
  width: 100%;
  min-height: 35%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
}

.form-group {
  padding-bottom: 0.5em;
}

.form-group input {
  padding: 0.175rem 0.175rem 0.0875rem;
  border: 0;
  border-bottom: 2px solid #eee;
  line-height: 1.9;
  width: 80%;
  transition: all 0.28s ease;
  box-shadow: none;
}

.form-group input:focus {
  border-color: #333;
}

.form-group input[type="submit"] {
  padding: 5px 15px;
  background: #ccc;
  border: 0 none;
  cursor: pointer;
}

div.login-form {
  display: flex;
  align-items: center;
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5em;
  padding: 0 0.5em 0 0.5em;
}

#header .brand {
  align-items: inherit;
  font-size: 1.5em;
}

#header .text {
  flex-grow: 1;
}

#header .brand img {
  padding-right: .3em;
  max-height: 1.5em;
  object-fit: contain;
}

#header .navigation .user {
  font-size: 1.5em;
  padding-right: .5em;
}

#header .navigation .menu {
  cursor: pointer;
}

#header .brand-logo > img {
    height: 64px;
}

#header .navigation .user {
  font-size: 1.5em;
  padding-right: .5em;
}

#header .navigation .menu {
  cursor: pointer;
}

.brand {
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.combo-btn {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  text-align: center;
}

.combo-item {
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1rem;
}

.combo-item-text {
  display: none;
}
@media (min-device-width: 30em) {
  .combo-item-text {
    display: inline;
  }
}

.combo-item:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.combo-item:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.combo-item + .combo-item {
  border-left: 0.1rem solid #888;
}

.combo-item-icon {
  padding: 0 0.5rem 0 0.5rem;
}

.combo-item.blue {
  color: white;
  background-color: #3c6cde;
  border: 0.1rem solid #3c6cde;
}

.combo-item.blue:hover {
  color: #3c6cde;
  background-color: white;
}

.combo-item.white {
  color: #3c6cde;
  background-color: white;
  border: 0.1rem solid #3c6cde;
}

.combo-item.white:hover {
  color: white;
  background-color: #3c6cde;
}

.combo-item.red {
  color: white;
  background-color: red;
  border: 0.1rem solid red;
}

.combo-item.red:hover {
  color: red;
  background-color: white;
}

.combo-item.gray {
  color: white;
  background-color: #555;
  border: 0.1rem solid #555;
}

.combo-item.gray:hover {
  color: #555;
  background-color: white;
}

#sidebar {
  color: #ffffff;
  background-color: #2a354d;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 5rem;
  border-right: #eaf0fd solid 0.1rem;
}

#sidebar a {
  text-decoration: none;
  color: #ffffff;
}

#sidebar a:visited {
  text-decoration: none;
  color: #ffffff;
}

#sidebar .sidebar-name {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.5rem;
  font-weight: bolder;
  margin: 0;
  -webkit-user-select: none;
          user-select: none;
}

#sidebar .sidebar-name {
  visibility: hidden;
}

#sidebar .btn-sidebar-item:hover ~ .sidebar-name {
  visibility: visible;
}

#sidebar .btn-sidebar-item {
  width: 3rem;
  height: 3rem;
  background-color: #3c6cde;
  border-radius: 0.8rem;
  margin: 1rem 1rem 0.25rem 1rem;
  text-align: center;
  cursor: pointer;
}

#sidebar .btn-sidebar-item:hover {
  color: #2a354d;
  background-color: #ffffff9f;
}

#sidebar .btn-sidebar-item > i {
  font-size: 1.5rem;
  margin: 0.75rem;
}

#sidebar .fill {
  flex: 1 1 auto;
}

#sidebar .sidebar-footer {
  padding-bottom: 0.8rem;
}

#sidebar > .sidebar-item-active .btn-sidebar-item {
  color: #2a354d;
  background-color: #ffffff;
  cursor: default;
}

#sidebar > .sidebar-item-active .sidebar-name {
  visibility: visible;
}

#directory {
  display: flex;
  flex-direction: row;
  height: 100%;
}

#directory .directories-header {
  display: flex;
  align-items: center;
  color: #2a354d;
  background-color: #ffffff;
  font-size: 1.5rem;
  position: relative;
  padding: 1rem;
}
@media (min-device-width: 30em) {
  #directory .directories-header {
    height: 6rem;
    max-height: 6rem;
  }
}

#directory .directories-header-msg {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#directory .directories-header-msg > i {
  padding-right: 1rem;
}

#directory .directories-header .menu {
  display: none;
  padding: 1rem;
}
@media (max-device-width: 30em) {
  #directory .directories-header .menu {
    display: block;
  }
}

#directory .directories-header-fill {
  flex-grow: 1;
}
@media (max-device-width: 30em) {
  #directory .directories-header-btns {
    display: none;
  }
}

#directory .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #eaf0fd;
}

#directory .content-panel {
  margin: 4rem;
}

#directory .header {
  color: #3d6bdd;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: #3d6bdd solid 0.1rem;
}

#directory .table-header {
  color: black;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}

#directory .table-info {
  padding: 0.5rem 0 0.5rem 0;
}

#directory .row {
  margin-left: 0;
  margin-right: 0;
  clear: both;
}

#directory .row-fill {
  height: 100%;
  background-color: #eaf0fd;
}

#directory .body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem 4rem;
  overflow-y: scroll;
}
@media (max-device-width: 30em) {
  #directory .body {
    padding: 1rem 1rem;
  }
}

#directory .directory-header {
  height: 8rem;
  max-height: 8rem;
  width: 100%;
  display: flex;
  background-color: white;
}

#directory .directory-brand {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 18rem;
  color: #ffffff;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#directory .directory-header-msg {
  padding: 2rem 1rem 2rem 2rem;
  font-size: 3rem;
  font-weight: bold;
  width: 30%;
  min-width: 30rem;
}

#directories-column .brand {
  display: flex;
  height: 8rem;
}

#directories-column {
  width: 18rem;
  color: #ffffff;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#directories-column .directories-selections {
  margin-left: 1.5rem;
}

#directories-column .directories-selections-header {
  display: block;
  background: #6a97fd;
  height: 2rem;
  line-height: 2rem;
  padding-left: 0.6rem;
  font-weight: bold;
  margin: 0.1rem 0.1rem 0.1rem 0;
}

#directories-column .directories-selection.active {
  color: black;
  background: #eaf0fd;
}

#directories-column .directories-selection {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border-bottom: 0.1rem solid #3c6cde;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
  padding-left: 1.5rem;
}

#directories-column .directories-selection > i {
  padding-right: 0.5rem;
}

#directories-column .directories-active {
  color: #000000;
  background-color: #eaf0fd;
}

#directories-column .directories-selection:last-child {
  border-bottom: none;
}

.contact-detail-name {
  font-weight: bold;
}

.contact-detail-phone > span > i {
  padding: 0 0.5rem 0 0.5rem;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 350px;
  min-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #fff; /* A background-color is required */
  min-height: 100%;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

@media screen and (min-width: 780px) {
  .slideout-panel {
    margin-left: 350px;
    width: 100%;
  }

  .slideout-menu {
    display: block;
  }

  .btn-hamburger {
    display: none;
  }
}

#panel {
  width: 100%;
}

.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 1rem 1rem 1rem;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  cursor: pointer;
  color: white;
  background-color: #3c6cde;
  border-radius: 3rem;
}

.circle-button.disabled {
  cursor: not-allowed !important;
  color: black !important;
  background-color: lightgray !important;
}

.circle-button.btn-small {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  min-height: 2rem;
  cursor: pointer;
  line-height: 2rem;
  border-radius: 2rem;
}

.circle-button.red {
  color: red;
  border: solid red 1px;
  background-color: white;
}

.circle-button.red:hover {
  color: white;
  background-color: red;
}

.form-input {
  height: 3rem;
  margin-bottom: 1rem;
}

.form-input > .form-input-background {
  height: 100%;
  width: 100%;
  border: none;
}

.form-input > .form-input-container {
  position: relative;
  padding: 1rem;
  background-color: transparent;
  border-radius: 1rem;
  line-height: 1.2rem;
  position: relative;
  top: -4rem;
  width: calc(100% - 2rem);
}

.form-input > .form-input-container > input {
  height: 100%;
  width: 100%;
  color: #3b6adf;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  border: none;
  border-bottom: #8aa4dc solid 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
}

.form-input > .form-input-container > input:disabled {
  color: gray;
  border-bottom: gray solid 0.1rem;
}

.form-input > .form-input-info {
  font-size: 0.6rem;
  font-weight: bold;
  position: relative;
  top: -5rem;
  margin: 0.4rem;
  padding-left: 1.2rem;
  color: #8aa4dc;
}

.form-select {
  height: 3rem;
  margin-bottom: 1rem;
}

.form-select > .form-select-background {
  height: 100%;
  width: 100%;
  border: none;
}

.form-select > .form-select-container {
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  line-height: 1.2rem;
  position: relative;
  top: -4rem;
  width: calc(100% - 2rem);
}

.form-select > .form-select-container > select {
  height: 100%;
  width: 100%;
  color: #3b6adf;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  border: none;
  border-bottom: #8aa4dc solid 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
}

.form-select > .form-select-container > select:disabled {
  color: gray;
  border-bottom: gray solid 0.1rem;
}

.form-select > .form-select-info {
  font-size: 0.6rem;
  font-weight: bold;
  position: relative;
  top: -5rem;
  margin: 0.4rem;
  padding-left: 1.2rem;
  color: #8aa4dc;
}

.header-button {
  display: inline;
  cursor: pointer;
  padding: 1rem;
  color: white;
  background-color: #3c6cde;
  border-radius: 1rem;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.header-button.btn-small {
  padding: 0.6rem;
  line-height: 1rem;
  font-size: 0.6rem;
}

.header-button.btn-white {
  color: #3c6cde;
  background-color: white;
  border: 0.1rem solid #3c6cde;
}

.header-button.btn-white:hover {
  color: white;
  background-color: #3c6cde;
}

.header-button.btn-red {
  color: red;
  background-color: white;
  border: 0.1rem solid red;
}

.header-button.btn-red:hover {
  color: white;
  background-color: red;
}

.header-button-btn > i {
  margin-right: 0.2rem;
}

.header-button-btn {
  white-space: nowrap;
}

.header-button.disabled {
  cursor: not-allowed !important;
  color: black !important;
  background-color: lightgray !important;
  border: 0.1rem solid black;
}

.contact-form-row-btns .header-button {
  margin-right: 1rem;
}


.tbl {
  display: table;
  width: 100%;
}
.tbl-header {
  display: table-row-group;
}
.tbl-header > .tbl-row {
  display: table-row;
}
.tbl-header > .tbl-row > .tbl-cell {
  display: table-cell;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 1rem;
  color: #3d6bdd;
  border-bottom: 0.1rem solid black;
}
.tbl-body {
  display: table-row-group;
}
.tbl-body > .tbl-row > .tbl-cell {
  padding: 0.5rem;
  border-bottom: 0.1rem solid #dfe5fc;
}
.tbl-body > .tbl-row {
  display: table-row;
  padding-top: 0.5rem;
}
.tbl-body > .tbl-row:hover {
  color: #ffffff;
  background-color: #2a354d;
  cursor: pointer;
}
.tbl-body {
  display: table-row-group;
}
.tbl-cell {
  display: table-cell;
}

.beta-wrapper {
  position: absolute;
  z-index: 2;
  width: 100%;
  font-size: 24px;
}

.beta {
  background: green;
  justify-content: center;
  display: flex;
  color: white;
  padding: 2px 0px;
}

a {
  color: white;
  text-decoration: none;
}

.x {
  background-color: black;
  border-radius: 5px;
  position: relative;
  right: 10px;
  float: right;
}

.x:after {
  position: absolute;
  top: -21px;
  content: "\D7";
  font-size: 50px;
  color: white;
  line-height: 10px;
  text-align: center;
  right: 10px;
  cursor: pointer;
}

#contacts-list .tbl-name {
  font-weight: bold;
}

#contacts-list .tbl-number > span:not(:first-child) {
  padding-left: 1rem;
}

#contacts-list .tbl-number > span > i {
  width: 1rem;
}

#contacts-list .tbl-body > .tbl-row:hover {
  color: white !important;
  background-color: #2a354d;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

#contacts-list .tbl-actions {
  text-align: right;
}

#contacts-list .tbl-actions a:link {
  color: inherit;
  text-decoration: none;
}

#contacts-list .tbl-actions a:visited {
  color: inherit;
  text-decoration: none;
}

#messages-app {
  display: flex;
  flex-flow: row;
  height: 100%;
}

#messages-app #rooms-column {
  width: 18rem;
}

#messages-app #panel #messages {
  width: 100%;
  height: 100%;
}

#slideout {
  display: flex;
  flex-direction: row;
}

#messages .conversation .header {
  display: flex;
  align-items: center;
  color: #2a354d;
  background-color: #ffffff;
  font-size: 1.5rem;
  position: relative;
  padding: 1rem;
}
@media (min-device-width: 30em) {
  #messages .conversation .header {
    min-height: 6rem;
  }
}

#messages .conversation .header .menu {
  display: none;
  padding: 1rem;
}
@media (max-device-width: 30em) {
  #messages .conversation .header .menu {
    display: inline-block;
  }
}

#messages .conversation .header .pic {
  position: absolute;
  top: 0.5em;
  left: 0.75em;
}

#messages .conversation .header .pic img {
  max-height: 2em;
  border-radius: 50%;
  border: 1px solid #212128;
}

#messages .conversation .header .type-small {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: 10;
  font-size: 0.8em;
}

.header > .type {
  padding-left: 0.3em;
  padding-right: 2.3em;
}

.header-new > .resource > .type {
  display: inline-block;
  width: 4rem;
  padding-left: 0rem;
  padding-right: 0rem !important;
  text-align: center;
  font-size: 3rem !important;
}

.header-new > .resource > input {
  color: #444444;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-bottom: 2px solid #ccc;
  transition: all 0.28s ease;
  box-shadow: none;
  width: calc(100% - 4rem);
  font-size: 3rem !important;
}

.header-new > .btn-confirm-new {
  display: inline-block;
}

@media (min-device-width: 30em) {
  .header-new > .new-message-begin-btn {
    margin-bottom: 2rem;
  }
}

.header-new > .hdr-new-room > select {
  color: #444444;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.175rem 0.175rem 0.0875rem;
  border: 0;
  border-bottom: 2px solid #ccc;
  transition: all 0.28s ease;
  box-shadow: none;
  font-size: 3rem;
}

.header-new > .btn-confirm-new {
  color: #444444;
  margin-left: 2rem;
}

#messages .header-btns {
  width: 100%;
  display: flex;
}
@media (max-device-width: 30em) {
  #messages .header-btns {
    display: none;
  }
}

#messages .header-btns > .header-fill {
  flex: 1 1;
}

#messages .contact-name {
  white-space: nowrap;
}

#messages .contact-name .archived {
  color: #e57373;
}

.header-button:not(:first-child) {
  margin-left: 1rem;
}

/* Modal */
.popup-content {
  padding: 0rem !important;
  border-radius: 1rem;
  border: 0 !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-radius: 1rem 1rem 0 0;
  color: #1a3dc8;
  font-weight: bold;
  background-color: #e8edfb;
  border-bottom: 0.1rem solid #c5d3f5;
  padding: 1rem;
}

.modal-header > i {
  cursor: pointer;
}

.modal-content {
  padding: 1rem;
}

.modal-content > p {
  font-size: 1rem;
}

.modal-confirm {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.media-box {
  height: auto;
  font-size: 0;
  line-height: 0;
}

.media-box > img {
  min-height: 4rem;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
}

.media-box-inbound {
  text-align: left;
}

.media-box-outbound {
  text-align: right;
}

.msg {
  display: inline-block;
  border-radius: 1em;
  text-align: left;
}

.msg p {
  padding: 1em;
  margin: 0;
}

.msg-inbound {
  color: #000000;
  background-color: #ffffff;
  max-width: 85%;
}

.msg-outbound {
  color: #fff;
  background-color: #3c6cde;
  max-width: 85%;
}

.msg-box {
  padding: 0.5em;
}

.msg-box > .media-box {
  width: 50%;
}

.msg-box-inbound {
  text-align: left;
}

.msg-box-outbound {
  text-align: right;
}

.msg-box > .media-box + .msg {
  width: 100%;
  max-width: 50%;
  margin-top: 0;
  border-radius: 0 0 1em 1em;
}

.msg-timestamp {
  font-family: 'Public Sans', serif;
  font-size: 13px;
}

#messages {
  padding: 0;
  color: #818189;
  background-color: #eaf0fd;
}

#messages .conversation {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#messages .conversation header {
  flex: 0 1 auto;
}

#messages .conversation .messages-box {
  display: flex;
  flex-grow: 1;
  flex-shrink: 10000;
  overflow-y: scroll;
  padding-top: 0.8rem;
  font-family: "Raleway", sans-serif;
}

#messages .conversation .messages-box::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

#messages .conversation .messages-box::-webkit-scrollbar-track {
  background: transparent;
}

#messages .conversation .messages-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

#messages .conversation .messages-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#messages .conversation .messages-box .msgs-box {
  height: 100%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-device-width: 30em) {
  #messages .conversation .messages-box .msgs-box {
    width: 95%;
  }
}

#messages .conversation .footer {
  display: flex;
  justify-content: center;
  background-color: #eaf0fd;
  padding: 1rem;
  min-height: 5rem;
}
@media (max-device-width: 30em) {
  #messages .conversation .footer {
    padding: 0;
  }
}

#messages > .conversation > .footer > .message-window {
  width: 80%;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem 1.5rem 0rem 3rem;
  font-size: 2rem;
  vertical-align: bottom;
  max-height: 20rem;
  display: flex;
  flex-direction: column;
}
@media (max-device-width: 30em) {
  #messages > .conversation > .footer > .message-window {
    width: 100%;
    border-radius: 0;
  }
}

#messages > .conversation > .footer > .message-window > .send {
  display: flex;
  flex-direction: row-reverse;
}

#messages > .conversation > .footer > .message-window > .send > i {
  color: #3c6cde;
  cursor: pointer;
  position: absolute;
  -webkit-transform: translate(-1rem, -4rem);
          transform: translate(-1rem, -4rem);
}

#messages > .conversation > .footer > .message-window > textarea {
  width: calc(100% - 4rem);
  height: 3rem;
  min-height: 3rem;
  max-height: 9rem;
  color: #3c6cde;
  font-size: 1.5rem;
  border: none;
  border-bottom: 0.2rem solid #e6ecfc;
  padding-bottom: 0;
  padding-right: 4rem;
  font-family: "Raleway", sans-serif;
  line-height: 3rem;
  resize: none;
  outline: none;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-input-placeholder {
  color: #3c6cde;
  font-family: "Raleway", sans-serif;
}

#messages > .conversation > .footer > .message-window > textarea::placeholder {
  color: #3c6cde;
  font-family: "Raleway", sans-serif;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar {
  width: 10px;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar-track {
  background: transparent;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

#messages > .conversation > .footer > .message-window > textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#messages .conversation .header .type {
  padding-right: 1rem;
}

.signature {
  font-size: 0.75rem;
  margin: 5px 0;
  min-height: 1rem;
}

.header-input {
  height: 4rem;
  margin-bottom: 2rem;
}

.header-input > .header-input-background {
  height: 100%;
  width: 100%;
  border: none;
  background-color: #cfd5e9;
  -webkit-filter: blur(1rem);
          filter: blur(1rem);
}

.header-input > .header-input-container {
  position: relative;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  line-height: 1.5rem;
  position: relative;
  top: -4rem;
  width: calc(100% - 2rem);
}

.header-input > .header-input-container > input {
  height: 100%;
  width: 100%;
  color: #3b6adf;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: white;
  border: none;
  border-bottom: #e4ecfb solid 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
}

.header-input > .header-input-info {
  font-size: 0.6rem;
  position: relative;
  top: -4rem;
  margin: 0.4rem;
  padding-left: 1.5rem;
  color: #8aa4dc;
}

.search-message {
  margin-top: 0.75rem;
}
.search-table {
  border-spacing: 0 0.5rem;
  width: 100%;
  margin-bottom: -0.5rem;
}
.search-item {
  cursor: pointer;
}
.search-item-primary {
  vertical-align: middle;
  width: 50%;
}
.search-item-primary span {
  margin-right: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-item-secondary {
  text-align: right;
  font-size: 1rem;
  line-height: 1rem;
  width: 50%;
}
.search-item-secondary div {
  padding: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-select {
  height: 4rem;
  margin-bottom: 2rem;
}

.header-select > .header-select-background {
  height: 100%;
  width: 100%;
  border: none;
  background-color: #cfd5e9;
  -webkit-filter: blur(1rem);
          filter: blur(1rem);
}

.header-select > .header-select-container {
  position: relative;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  line-height: 1.5rem;
  position: relative;
  top: -4rem;
  width: calc(100% - 2rem);
}

.header-select > .header-select-container > select {
  height: 100%;
  width: 100%;
  color: #3b6adf;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: white;
  border: none;
  border-bottom: #e4ecfb solid 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
}

.header-select > .header-select-info {
  font-size: 0.6rem;
  position: relative;
  top: -4rem;
  margin: 0.4rem;
  padding-left: 1.5rem;
  color: #8aa4dc;
}

#conversations .conversation {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border-bottom: 0.1rem solid #3c6cde;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
}

#conversations .conversation-active {
  color: #000000;
  background-color: #eaf0fd;
}

#conversations .conversation:last-child {
  border-bottom: none;
}

#conversations .conversation > span.type {
  float: left;
  height: 100%;
  width: 3em;
  margin-right: 0.2rem;
  text-align: center;
}

#conversations .conversation > span.type > i {
  font-size: 1.5em;
  line-height: 1.25em;
}

#conversations .conversation > span.name {
  display: block;
  height: 100%;
  overflow: hidden;
  padding-left: 0.6rem;
}

#conversations .conversation > span.name > i {
  float: right;
  margin: 0.5em;
  /* the width and text align allow icons to be centered with each other */
  width: 15px;
  text-align: center;
}

i.yellow {
  color: #ffc000;
}

i.red {
  color: #e57373;
}

#conversations h1 {
  margin: 0 0 0 0;
  padding: 0;
  font-size: 1em;
}
.room {
  padding: 0em 0em 0em 1em;
}

.room-header {
  padding: 0.1rem 0.1rem 0.1rem 0;
  font-weight: bold;
  height: 2rem;
}

.room-header-expand {
  float: left;
  height: 100%;
  width: 3em;
  text-align: center;
  background: #6a97fd;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  margin-right: 0.2rem;
  line-height: 2rem;
}

.room-header-name {
  display: block;
  padding-left: 0.6rem;
  background: #6a97fd;
  height: 100%;
  overflow: hidden;
  line-height: 2rem;
}
.rooms {
  overflow-y: auto;
  overflow-x: hidden;
}

.rooms::-webkit-scrollbar {
  width: 10px;
}

.rooms::-webkit-scrollbar-track {
  background: #212128; 
}

.rooms::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.rooms::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.actions {
  display: flex;
  color: #818189;
  background-color: #2a354d7f;
  padding: 1rem;
}

.btn-new-msg {
  width: 80%;
  height: 2rem;
  margin: auto;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 2rem;
}

.btn-new-msg > span > i {
  padding-left: 0.5rem;
  line-height: 2rem;
}
#rooms-column {
  color: #FFFFFF;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#rooms-column > .brand {
  display: flex;
  height: 8rem;
}

#rooms-column > .rooms {
  height: calc(100% - 14rem);
}

#rooms-column > .actions {
  height: 4rem;
}

#settings-app {
  display: flex;
  flex-direction: row;
  height: 100%;
}

#settings-app #panel {
  display: flex;
  flex-direction: column;
}

#settings-app a {
  text-decoration: none;
  color: #ffffff;
}

#settings-app a:visited {
  text-decoration: none;
  color: #ffffff;
}

#settings-app #settings-column {
  width: 18rem;
  color: #ffffff;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#settings-column .settings-selections {
  margin-left: 1.5rem;
}

#settings-column .settings-selections-header {
  display: block;
  background: #6a97fd;
  height: 2rem;
  line-height: 2rem;
  padding-left: 0.6rem;
  font-weight: bold;
  margin: 0.1rem 0.1rem 0.1rem 0;
}

#settings-column .settings-selection.active {
  color: black;
  background: #eaf0fd;
}

#settings-column .settings-selection {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border-bottom: 0.1rem solid #3c6cde;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
  padding-left: 1.5rem;
}

#settings-column .settings-selection > i {
  padding-right: 0.5rem;
}

#settings-column .settings-selection:last-child {
  border-bottom: none;
}

#settings-app .content {
  width: 100%;
  background-color: #eaf0fd;
}

#settings-app .settings-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  font-size: 1.5rem;
  font-weight: bold;
  align-items: center;
  padding: 1rem;
}
@media (min-device-width: 30em) {
  #settings-app .settings-header {
    height: 6rem;
    max-height: 6rem;
  }
}
#settings-app .settings-header-msg {
  text-transform: capitalize;
}

#settings-app .settings-header-msg > i {
  padding-right: 1rem;
}

#settings-app .settings-header .menu {
  display: none;
  padding: 1rem;
}
@media (max-device-width: 30em) {
  #settings-app .settings-header .menu {
    display: block;
  }
}

#settings-app .settings-form {
  background-color: #eaf0fd;
  flex-grow: 1;
}
@media (min-device-width: 30em) {
  #settings-app .settings-form {
    padding: 2rem 4rem;
  }
}

#settings-app .settings-form-actons {
  display: flex;
  flex-direction: row-reverse;
}
#settings-app .settings-form-save {
  width: 10rem;
}

#settings-app .brand {
  height: 8rem;
}

.alert {
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0 4rem 1rem 4rem;
  font-weight: bold;
}

.alert > i {
  padding-right: 0.8rem;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

#settings-app .settings-form-actons {
  display: flex;
  flex-direction: row-reverse;
}
#settings-app .settings-form-save {
  width: 10rem;
}

#settings-app .messages {
  display: flex;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0 4rem 1rem 4rem;
  font-weight: bold;
}

#settings-app .messages > i {
  padding-right: 0.8rem;
}

#settings-app .error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

#settings-app .success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

#settings-app .settings-form-actons {
  display: flex;
  flex-direction: row-reverse;
}
#settings-app .settings-form-save {
  width: 10rem;
}

.error-message {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: .2em;
    border-radius: .2em;
    width: 80%;
    margin: auto;
}
