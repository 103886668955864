#directory {
  display: flex;
  flex-direction: row;
  height: 100%;
}

#directory .directories-header {
  display: flex;
  align-items: center;
  color: #2a354d;
  background-color: #ffffff;
  font-size: 1.5rem;
  position: relative;
  padding: 1rem;
}
@media (min-device-width: 30em) {
  #directory .directories-header {
    height: 6rem;
    max-height: 6rem;
  }
}

#directory .directories-header-msg {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#directory .directories-header-msg > i {
  padding-right: 1rem;
}

#directory .directories-header .menu {
  display: none;
  padding: 1rem;
}
@media (max-device-width: 30em) {
  #directory .directories-header .menu {
    display: block;
  }
}

#directory .directories-header-fill {
  flex-grow: 1;
}
@media (max-device-width: 30em) {
  #directory .directories-header-btns {
    display: none;
  }
}

#directory .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #eaf0fd;
}

#directory .content-panel {
  margin: 4rem;
}

#directory .header {
  color: #3d6bdd;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: #3d6bdd solid 0.1rem;
}

#directory .table-header {
  color: black;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}

#directory .table-info {
  padding: 0.5rem 0 0.5rem 0;
}

#directory .row {
  margin-left: 0;
  margin-right: 0;
  clear: both;
}

#directory .row-fill {
  height: 100%;
  background-color: #eaf0fd;
}

#directory .body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem 4rem;
  overflow-y: scroll;
}
@media (max-device-width: 30em) {
  #directory .body {
    padding: 1rem 1rem;
  }
}

#directory .directory-header {
  height: 8rem;
  max-height: 8rem;
  width: 100%;
  display: flex;
  background-color: white;
}

#directory .directory-brand {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 18rem;
  color: #ffffff;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#directory .directory-header-msg {
  padding: 2rem 1rem 2rem 2rem;
  font-size: 3rem;
  font-weight: bold;
  width: 30%;
  min-width: 30rem;
}

#directories-column .brand {
  display: flex;
  height: 8rem;
}

#directories-column {
  width: 18rem;
  color: #ffffff;
  background: linear-gradient(to right, #3c6cde, #6a97fd);
}

#directories-column .directories-selections {
  margin-left: 1.5rem;
}

#directories-column .directories-selections-header {
  display: block;
  background: #6a97fd;
  height: 2rem;
  line-height: 2rem;
  padding-left: 0.6rem;
  font-weight: bold;
  margin: 0.1rem 0.1rem 0.1rem 0;
}

#directories-column .directories-selection.active {
  color: black;
  background: #eaf0fd;
}

#directories-column .directories-selection {
  cursor: pointer;
  user-select: none;
  border-bottom: 0.1rem solid #3c6cde;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
  padding-left: 1.5rem;
}

#directories-column .directories-selection > i {
  padding-right: 0.5rem;
}

#directories-column .directories-active {
  color: #000000;
  background-color: #eaf0fd;
}

#directories-column .directories-selection:last-child {
  border-bottom: none;
}
