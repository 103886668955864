.msg {
  display: inline-block;
  border-radius: 1em;
  text-align: left;
}

.msg p {
  padding: 1em;
  margin: 0;
}

.msg-inbound {
  color: #000000;
  background-color: #ffffff;
  max-width: 85%;
}

.msg-outbound {
  color: #fff;
  background-color: #3c6cde;
  max-width: 85%;
}

.msg-box {
  padding: 0.5em;
}

.msg-box > .media-box {
  width: 50%;
}

.msg-box-inbound {
  text-align: left;
}

.msg-box-outbound {
  text-align: right;
}

.msg-box > .media-box + .msg {
  width: 100%;
  max-width: 50%;
  margin-top: 0;
  border-radius: 0 0 1em 1em;
}

.msg-timestamp {
  font-family: 'Public Sans', serif;
  font-size: 13px;
}
