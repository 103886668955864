.beta-wrapper {
  position: absolute;
  z-index: 2;
  width: 100%;
  font-size: 24px;
}

.beta {
  background: green;
  justify-content: center;
  display: flex;
  color: white;
  padding: 2px 0px;
}

a {
  color: white;
  text-decoration: none;
}

.x {
  background-color: black;
  border-radius: 5px;
  position: relative;
  right: 10px;
  float: right;
}

.x:after {
  position: absolute;
  top: -21px;
  content: "\D7";
  font-size: 50px;
  color: white;
  line-height: 10px;
  text-align: center;
  right: 10px;
  cursor: pointer;
}
