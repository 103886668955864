.alert {
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0 4rem 1rem 4rem;
  font-weight: bold;
}

.alert > i {
  padding-right: 0.8rem;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
