#settings-app .messages {
  display: flex;
  text-align: center;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0 4rem 1rem 4rem;
  font-weight: bold;
}

#settings-app .messages > i {
  padding-right: 0.8rem;
}

#settings-app .error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

#settings-app .success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

#settings-app .settings-form-actons {
  display: flex;
  flex-direction: row-reverse;
}
#settings-app .settings-form-save {
  width: 10rem;
}
