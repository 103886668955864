#sidebar {
  color: #ffffff;
  background-color: #2a354d;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 5rem;
  border-right: #eaf0fd solid 0.1rem;
}

#sidebar a {
  text-decoration: none;
  color: #ffffff;
}

#sidebar a:visited {
  text-decoration: none;
  color: #ffffff;
}

#sidebar .sidebar-name {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.5rem;
  font-weight: bolder;
  margin: 0;
  user-select: none;
}

#sidebar .sidebar-name {
  visibility: hidden;
}

#sidebar .btn-sidebar-item:hover ~ .sidebar-name {
  visibility: visible;
}

#sidebar .btn-sidebar-item {
  width: 3rem;
  height: 3rem;
  background-color: #3c6cde;
  border-radius: 0.8rem;
  margin: 1rem 1rem 0.25rem 1rem;
  text-align: center;
  cursor: pointer;
}

#sidebar .btn-sidebar-item:hover {
  color: #2a354d;
  background-color: #ffffff9f;
}

#sidebar .btn-sidebar-item > i {
  font-size: 1.5rem;
  margin: 0.75rem;
}

#sidebar .fill {
  flex: 1 1 auto;
}

#sidebar .sidebar-footer {
  padding-bottom: 0.8rem;
}

#sidebar > .sidebar-item-active .btn-sidebar-item {
  color: #2a354d;
  background-color: #ffffff;
  cursor: default;
}

#sidebar > .sidebar-item-active .sidebar-name {
  visibility: visible;
}
