.search-message {
  margin-top: 0.75rem;
}
.search-table {
  border-spacing: 0 0.5rem;
  width: 100%;
  margin-bottom: -0.5rem;
}
.search-item {
  cursor: pointer;
}
.search-item-primary {
  vertical-align: middle;
  width: 50%;
}
.search-item-primary span {
  margin-right: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-item-secondary {
  text-align: right;
  font-size: 1rem;
  line-height: 1rem;
  width: 50%;
}
.search-item-secondary div {
  padding: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
