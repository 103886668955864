.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 1rem 1rem 1rem;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  cursor: pointer;
  color: white;
  background-color: #3c6cde;
  border-radius: 3rem;
}

.circle-button.disabled {
  cursor: not-allowed !important;
  color: black !important;
  background-color: lightgray !important;
}

.circle-button.btn-small {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  min-height: 2rem;
  cursor: pointer;
  line-height: 2rem;
  border-radius: 2rem;
}

.circle-button.red {
  color: red;
  border: solid red 1px;
  background-color: white;
}

.circle-button.red:hover {
  color: white;
  background-color: red;
}
