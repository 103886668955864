.form-input {
  height: 3rem;
  margin-bottom: 1rem;
}

.form-input > .form-input-background {
  height: 100%;
  width: 100%;
  border: none;
}

.form-input > .form-input-container {
  position: relative;
  padding: 1rem;
  background-color: transparent;
  border-radius: 1rem;
  line-height: 1.2rem;
  position: relative;
  top: -4rem;
  width: calc(100% - 2rem);
}

.form-input > .form-input-container > input {
  height: 100%;
  width: 100%;
  color: #3b6adf;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  border: none;
  border-bottom: #8aa4dc solid 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
}

.form-input > .form-input-container > input:disabled {
  color: gray;
  border-bottom: gray solid 0.1rem;
}

.form-input > .form-input-info {
  font-size: 0.6rem;
  font-weight: bold;
  position: relative;
  top: -5rem;
  margin: 0.4rem;
  padding-left: 1.2rem;
  color: #8aa4dc;
}
