#conversations .conversation {
  cursor: pointer;
  user-select: none;
  border-bottom: 0.1rem solid #3c6cde;
  height: 2rem;
  line-height: 2rem;
  overflow: hidden;
}

#conversations .conversation-active {
  color: #000000;
  background-color: #eaf0fd;
}

#conversations .conversation:last-child {
  border-bottom: none;
}

#conversations .conversation > span.type {
  float: left;
  height: 100%;
  width: 3em;
  margin-right: 0.2rem;
  text-align: center;
}

#conversations .conversation > span.type > i {
  font-size: 1.5em;
  line-height: 1.25em;
}

#conversations .conversation > span.name {
  display: block;
  height: 100%;
  overflow: hidden;
  padding-left: 0.6rem;
}

#conversations .conversation > span.name > i {
  float: right;
  margin: 0.5em;
  /* the width and text align allow icons to be centered with each other */
  width: 15px;
  text-align: center;
}

i.yellow {
  color: #ffc000;
}

i.red {
  color: #e57373;
}
