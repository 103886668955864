.room {
  padding: 0em 0em 0em 1em;
}

.room-header {
  padding: 0.1rem 0.1rem 0.1rem 0;
  font-weight: bold;
  height: 2rem;
}

.room-header-expand {
  float: left;
  height: 100%;
  width: 3em;
  text-align: center;
  background: #6a97fd;
  cursor: pointer;
  user-select: none;
  margin-right: 0.2rem;
  line-height: 2rem;
}

.room-header-name {
  display: block;
  padding-left: 0.6rem;
  background: #6a97fd;
  height: 100%;
  overflow: hidden;
  line-height: 2rem;
}