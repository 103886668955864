.header-select {
  height: 4rem;
  margin-bottom: 2rem;
}

.header-select > .header-select-background {
  height: 100%;
  width: 100%;
  border: none;
  background-color: #cfd5e9;
  filter: blur(1rem);
}

.header-select > .header-select-container {
  position: relative;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  line-height: 1.5rem;
  position: relative;
  top: -4rem;
  width: calc(100% - 2rem);
}

.header-select > .header-select-container > select {
  height: 100%;
  width: 100%;
  color: #3b6adf;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: white;
  border: none;
  border-bottom: #e4ecfb solid 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
}

.header-select > .header-select-info {
  font-size: 0.6rem;
  position: relative;
  top: -4rem;
  margin: 0.4rem;
  padding-left: 1.5rem;
  color: #8aa4dc;
}
