.brand {
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
